exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-2-js": () => import("./../../../src/pages/about-us-2.js" /* webpackChunkName: "component---src-pages-about-us-2-js" */),
  "component---src-pages-backlinks-js": () => import("./../../../src/pages/backlinks.js" /* webpackChunkName: "component---src-pages-backlinks-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-seo-smart-kit-js": () => import("./../../../src/pages/seo-smart-kit.js" /* webpackChunkName: "component---src-pages-seo-smart-kit-js" */),
  "component---src-pages-smm-ads-js": () => import("./../../../src/pages/smm-ads.js" /* webpackChunkName: "component---src-pages-smm-ads-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-web-design-js": () => import("./../../../src/pages/web-design.js" /* webpackChunkName: "component---src-pages-web-design-js" */),
  "component---src-pages-xiaohongshu-js": () => import("./../../../src/pages/xiaohongshu.js" /* webpackChunkName: "component---src-pages-xiaohongshu-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

